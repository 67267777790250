import React, { useEffect, useRef } from 'react';
import styles from "./index.module.css";

interface EditableTextProps {
    description: string;
    onChange: (newDescription: string) => void;
    maxLength?: number;
    disabled?: boolean;
}

const EditableText: React.FC<EditableTextProps> = (
    {
        description,
        onChange,
        maxLength = 300,
        disabled = false
    }
) => {
    const editableRef = useRef<HTMLDivElement>(null);
    const isComposing = useRef(false); // Для отслеживания режима ввода (например, IME)

    const getTextLength = (html: string): number => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = html;
        return tempElement.textContent?.length || 0;
    };

    const trimToMaxLength = (html: string, maxLength: number): string => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = html;
        let textContent = tempElement.textContent || '';

        if (textContent.length > maxLength) {
            textContent = textContent.substring(0, maxLength);
            tempElement.textContent = textContent;
        }

        return tempElement.innerHTML;
    };

    const saveCursorPosition = (): { node: Node, offset: number } | null => {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            return { node: range.startContainer, offset: range.startOffset };
        }
        return null;
    };

    const restoreCursorPosition = (savedPosition: { node: Node, offset: number } | null) => {
        if (savedPosition) {
            const selection = window.getSelection();
            if (selection) {
                const range = document.createRange();
                range.setStart(savedPosition.node, savedPosition.offset);
                range.collapse(true);
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }
    };

    // Очистка HTML от нежелательных тегов
    const cleanHtml = (html: string): string => {
        const allowedTags = ['b', 'i', 'u', 'br']; // Разрешенные теги
        const tempElement = document.createElement('div');
        tempElement.innerHTML = html;

        // Удаляем все теги, кроме разрешенных
        const nodes = tempElement.querySelectorAll('*');
        nodes.forEach((node) => {
            if (!allowedTags.includes(node.tagName.toLowerCase())) {
                // Заменяем нежелательные теги на их текстовое содержимое
                const textNode = document.createTextNode(node.textContent || '');
                node.replaceWith(textNode);
            }
        });

        return tempElement.innerHTML;
    };

    const handleInput = () => {
        if (isComposing.current) return; // Игнорируем ввод во время композиции (IME)

        const target = editableRef.current;
        if (!target) return;

        const innerHTML = target.innerHTML;

        // Проверяем длину текста без учета HTML-тегов
        const textLength = getTextLength(innerHTML);

        if (textLength > maxLength) {
            // Если текст превышает лимит, обрезаем его до максимальной длины
            const trimmedHTML = trimToMaxLength(innerHTML, maxLength);
            target.innerHTML = trimmedHTML; // Обновляем содержимое элемента
            //restoreCursorPosition(saveCursorPosition()); // Восстанавливаем курсор
        }
    };

    const handleBlur = () => {
        // При потере фокуса обновляем состояние
        if (editableRef.current) {
            onChange(editableRef.current.innerHTML);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        const nativeEvent = e.nativeEvent as KeyboardEvent;
        const currentLength = getTextLength(editableRef.current?.innerHTML || '');

        if (currentLength >= maxLength) {
            const isControlKey = [
                'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight',
                'ArrowUp', 'ArrowDown', 'Tab', 'Home', 'End'
            ].includes(nativeEvent.key);

            const isFormatShortcut = (nativeEvent.ctrlKey || nativeEvent.metaKey) &&
                ['b', 'i', 'u', 'и', 'ш', 'г'].includes(nativeEvent.key.toLowerCase());

            if (!isControlKey && !isFormatShortcut && nativeEvent.key.length === 1) {
                e.preventDefault();
            }
        }
    };

    // Обработка вставки текста
    const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
        e.preventDefault(); // Отменяем стандартное поведение вставки

        const text = e.clipboardData.getData('text/plain'); // Получаем текст из буфера обмена
        const cleanedText = cleanHtml(text); // Очищаем текст от нежелательных тегов

        // Вставляем очищенный текст
        document.execCommand('insertText', false, cleanedText);
    };

    // Обработка событий композиции (для IME)
    const handleCompositionStart = () => {
        isComposing.current = true;
    };

    const handleCompositionEnd = () => {
        isComposing.current = false;
    };

    useEffect(() => {
        if (editableRef.current && editableRef.current.innerHTML !== description) {
            // Устанавливаем начальное значение
            editableRef.current.innerHTML = description;
        }
    }, [description]);

    return (
        <div
            ref={editableRef}
            contentEditable={!disabled}
            className={styles.editableText}
            onInput={handleInput}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            onPaste={handlePaste}
            onCompositionStart={handleCompositionStart}
            onCompositionEnd={handleCompositionEnd}
            suppressContentEditableWarning // Подавляем предупреждение о contentEditable
        />
    );
};

export default EditableText;